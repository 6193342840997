import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Rating, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  CircularProgress,
  Avatar,
  Box,
  Pagination,
  Stack,
  Link
} from '@mui/material';
import { connect } from 'react-redux';
import { apiendpoint } from '../endpoint';

const REPORT_ISSUES = [
  'Select an issue',
  'Inappropriate behavior',
  'No-show',
  'Technical issues',
  'Language barrier',
  'Unprofessional conduct',
  'Harassment',
  'Poor mentorship quality',
  'Other'
];

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

const MentorshipCallCard = ({ call, onStatusUpdate,user, token }) => {
  const [openReview, setOpenReview] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState({ rating: 0, text: '' });
  const [report, setReport] = useState({ issue: 'Select an issue', description: '' });

  const handleStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'scheduled':
        return '#4CAF50';
      case 'completed':
        return '#2196F3';
      default:
        return '#9E9E9E';
    }
  };

  // Mock functions for submitting review/report/mark done
  const handleSubmitReview = () => {};


  const handleSubmitReport = async (call_id, status, review_text) => {
    try {
         setLoading(true)
        const response = await fetch(`${apiendpoint.path}/v1/users/submitreport`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ call_id: call_id, status,review_text  }),
        });
       
        setOpenReport(false)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }

  };
  const handleMarkDone = async(call_id) => {
    try {
        setLoading(true)
        const response = await fetch(`${apiendpoint.path}/v1/users/updatecallstatus`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ call_id: call_id, status:"completed"  }),
        });
       if(response.ok){
        setOpenReview(true)
       }
       setLoading(false)
      } catch (error) {
        setLoading(false)
      }

  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography 
            variant="caption" 
            sx={{ 
              bgcolor: handleStatusColor(call.status), 
              color: 'white', 
              px: 1, 
              py: 0.5, 
              borderRadius: 1 
            }}
          >
            {call.status.toUpperCase()}
          </Typography>
          <Typography variant="body2">{formatDate(call.scheduled_at)}</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar src={call.topper_pic} sx={{ mr: 2 }} />
          <Box>
            <Typography variant="subtitle1">Mentor: {call.topper_name}</Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar src={call.student_pic} sx={{ mr: 2 }} />
          <Box>
            <Typography variant="subtitle1">Student: {call.student_name}</Typography>
          </Box>
        </Box>

        <Link 
          href={call.meet_link} 
          target="_blank" 
          rel="noopener" 
          sx={{ display: 'block', mb: 2 }}
        >
          Join Meeting
        </Link>

        <Box sx={{ display: 'flex', gap: 2 }}>
         {/* {!user.verified ? <Rating   value={review.rating}
              onChange={(event, newValue) => {
                setReview(prev => ({ ...prev, rating: newValue }));
              }}></Rating>:<Button 
            variant="contained" 
            color="primary" 
            onClick={()=>{
                handleMarkDone(call.call_id)
                call.status="completed"
            }} 
            disabled={loading || call.status.toLowerCase() === 'completed'}
          >
            {loading ? <CircularProgress size={24} /> : 'Mark Done'}
          </Button>} */}
          {/* <Button 
            variant="outlined" 
            color="secondary" 
            onClick={() => setOpenReport(true)} 
            disabled={loading}
          >
            Report
          </Button> */}
        </Box>
      </CardContent>
      <Dialog open={openReview} onClose={() => setOpenReview(false)}>
        <DialogTitle>Submit Review</DialogTitle>
        <DialogContent>
          <Box sx={{ my: 2 }}>
            <Rating
              value={review.rating}
              onChange={(event, newValue) => {
                setReview(prev => ({ ...prev, rating: newValue }));
              }}
            />
          </Box>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Review"
            value={review.text}
            onChange={(e) => setReview(prev => ({ ...prev, text: e.target.value }))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReview(false)}>Cancel</Button>
          <Button onClick={handleSubmitReview} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Report Dialog */}
      <Dialog open={openReport} onClose={() => setOpenReport(false)}>
        <DialogTitle>Submit Report</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            label="Issue Type"
            value={report.issue}
            onChange={(e) => setReport(prev => ({ ...prev, issue: e.target.value }))}
            sx={{ my: 2 }}
          >
            {REPORT_ISSUES.map((issue) => (
              <MenuItem key={issue} value={issue}>
                {issue}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Description"
            value={report.description}
            onChange={(e) => setReport(prev => ({ ...prev, description: e.target.value }))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReport(false)}>Cancel</Button>
          <Button onClick={()=>{handleSubmitReport(call.call_id,report.issue,report.description)}} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

const MentorshipCallCardAdmin = ({ data, token,user }) => {
  const [calls, setCalls] = useState(data.calls);
  const [currentPage, setCurrentPage] = useState(1);
  const [callsPerPage] = useState(5);

  const totalPages = Math.ceil(data.pagination.totalPages);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    // Fetch data for the new page if necessary.
  };

  const paginatedCalls = calls.slice(
    (currentPage - 1) * callsPerPage, 
    currentPage * callsPerPage
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>Mentorship Calls</Typography>
      <Stack spacing={3}>
        {paginatedCalls.map((call) => (
          <MentorshipCallCard 
            key={call.call_id} 
            call={call} 
            user={user}
            token={token}
            onStatusUpdate={() => {}} // Add status update logic.
          />
        ))}
      </Stack>
      <Pagination 
        count={totalPages} 
        page={currentPage} 
        onChange={handlePageChange} 
        color="primary" 
        sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    token:state.auth.token
});

export default connect(mapStateToProps)(MentorshipCallCardAdmin);
