import React, { useEffect, useState } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, AtomicBlockUtils, CompositeDecorator } from 'draft-js';
import axios from 'axios';
import 'draft-js/dist/Draft.css';
import { Camera, Link, Tags } from 'lucide-react';
import { 
  Button, 
  Input, 
  TextField, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  Select,
  MenuItem,
  Chip,
  Box
} from '@mui/material';
import verboace from '../images/verboace.png';
import './blogs.css';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { apiendpoint } from '../endpoint';
import HomeTop from '../TopHome';

const Image = (props) => {
  const { src } = props.contentState.getEntity(props.block.getEntityAt(0)).getData();
  return <img src={src} alt="Blog content" style={{ maxWidth: '100%', height: 'auto', margin: '1em 0' }} />;
};

const createDecorator = () => new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
      }, callback);
    },
    component: (props) => {
      const { url } = props.contentState.getEntity(props.entityKey).getData();
      return (
        <a href={url} style={{ color: 'blue', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      );
    },
  }
]);

const BlogPostEditor = ({ token, user }) => {
  const history = useHistory();

  useEffect(() => {
    if (!token) {
      history.push('/');
    }
  }, [token, history]);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(createDecorator()));
  const [title, setTitle] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState('');
  const [status, setStatus] = useState('draft');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSeoFields, setShowSeoFields] = useState(false);
  const [featured_image,setFeaturedImageUrl] = useState(null)

  const handleAddTag = () => {
    if (currentTag.trim() && !tags.includes(currentTag.trim())) {
      setTags([...tags, currentTag.trim()]);
      setCurrentTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      const contentState = editorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const plainText = contentState.getPlainText();
      const defaultMetaDescription = plainText.substring(0, 155) + '...';

      const blogData = {
        title,
        content: rawContentState,
        metaDescription: metaDescription || defaultMetaDescription,
        metaKeywords: metaKeywords || title.split(' ').join(','),
        tags,
        status,
        image_url: featured_image
      };

      const response = await axios.post(
        `${apiendpoint.path}/v1/users/blogs/blog`,
        blogData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
        
      );
      if(response.status==201){
        history.push('/')
      }
    } catch (error) {
      console.error('Failed to save blog post:', error);
      alert('Failed to save blog post. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('picture', file);

      try {
        const response = await axios.post(
          `${apiendpoint.path}/v1/users/uploadblogpic`,
          formData,
          {
            headers: { 
              'Content-Type': 'multipart/form-data', 
              Authorization: `Bearer ${token}` 
            },
          }
        );
        const uploadedImageUrl = response.data.publicUrl;
        setImageUrl(uploadedImageUrl);
        if(!featured_image){
            setFeaturedImageUrl(uploadedImageUrl)
        }
        
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
          'IMAGE',
          'IMMUTABLE',
          { src: uploadedImageUrl }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
          editorState,
          { currentContent: contentStateWithEntity }
        );
        const newStateWithImage = AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          ' '
        );
        setEditorState(newStateWithImage);
      } catch (error) {
        console.error('Upload failed:', error);
        alert('Image upload failed. Please try again.');
      }
    }
  };

  const blockStyleFn = (block) => {
    switch (block.getType()) {
      case 'header-one':
        return 'heading-one';
      case 'header-two':
        return 'heading-two';
      case 'header-three':
        return 'heading-three';
      default:
        return null;
    }
  };


  const blockRendererFn = (block) => {
    if (block.getType() === 'atomic') {
      const contentState = editorState.getCurrentContent();
      const entity = block.getEntityAt(0);
      if (!entity) return null;
      const type = contentState.getEntity(entity).getType();
      if (type === 'IMAGE') {
        return {
          component: Image,
          editable: false,
        };
      }
    }
    return null;
  };

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleBoldClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  };

  const handleItalicClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  };

  const handleUnderlineClick = () => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };

  const handleAddLink = () => {
    setIsLinkDialogOpen(true);
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const handleLinkInsert = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: linkUrl });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.push(editorState, contentStateWithEntity, 'insert-characters');
    setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    setLinkUrl('');
    setIsLinkDialogOpen(false);
  };

  return (
    <div style={{ minHeight: '100vh', backgroundColor: 'white', padding: 16, display: 'flex',justifyContent:"center",alignItems:"center" }}>
       <div>
        <HomeTop></HomeTop>
        </div>
      <div className="blog-post-editor" style={{marginTop:10+"%"}}>
        <Input
          type="text"
          placeholder="Blog Post Title"
          value={title}
          disableUnderline
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
        />

        <Select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          fullWidth
          margin="dense"
          className="mb-4"
        >
          <MenuItem value="draft">Draft</MenuItem>
          <MenuItem value="published">Published</MenuItem>
        </Select>

        <Box className="mb-4">
          <div className="flex gap-2 mb-2">
            <Input
              value={currentTag}
              onChange={(e) => setCurrentTag(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleAddTag()}
              placeholder="Add tags..."
              size="small"
              disableUnderline
            />
            <Button 
              onClick={handleAddTag}
              variant="outlined"
              style={{marginTop:2}}
              startIcon={<Tags size={16} />}
            >
              Add Tag
            </Button>
          </div>
          <div className="flex flex-wrap gap-2">
            {tags.map(tag => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => handleRemoveTag(tag)}
                size="small"
              />
            ))}
          </div>
        </Box>

        <Button 
          onClick={() => setShowSeoFields(!showSeoFields)}
          variant="text"
          className="mb-4"
        >
          {showSeoFields ? 'Hide SEO Fields' : 'Show SEO Fields'}
        </Button>

        {showSeoFields && (
          <div className="mb-4">
            <TextField
              fullWidth
              label="Meta Description"
              multiline
              style={{marginTop:10}}
              rows={2}
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
              helperText={`${metaDescription.length}/160 characters`}
              error={metaDescription.length > 160}
              className="mb-4"
            />
            <Input
              fullWidth
              label="Meta Keywords"
              disableUnderline
              placeholder='Meta Keywords'
              margin="normal"
              style={{borderStyle:"none",marginTop:5}}
              value={metaKeywords}
              onChange={(e) => setMetaKeywords(e.target.value)}
              helperText="Separate keywords with commas"
            />
          </div>
        )}

        <div className="editor-container">
          <div className="toolbar">
          <button onClick={() => toggleBlockType('header-two')}>H1</button>
          <button onClick={() => toggleBlockType('header-one')}>H2</button>
        <button onClick={() => toggleBlockType('header-three')}>H3</button>
        <button onClick={() => toggleBlockType('unstyled')}>Normal</button>
            <button onClick={handleBoldClick}><strong>B</strong></button>
            <button onClick={handleItalicClick}><em>I</em></button>
            <button onClick={handleUnderlineClick}><u>U</u></button>
            <label htmlFor="image-upload">
              <Camera size={20} />
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
            </label>
            <button onClick={handleAddLink}><Link size={20} /></button>
          </div>
          <Editor 
            editorState={editorState}
            onChange={handleEditorChange}
            blockStyleFn={blockStyleFn}
            blockRendererFn={blockRendererFn}
            placeholder="Start writing your blog post..."
          />
        </div>

        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          fullWidth
          disabled={isSubmitting || !title || !editorState.getCurrentContent().hasText()}
        >
          {isSubmitting ? 'Saving...' : 'Save Post'}
        </Button>
      </div>

      <Dialog open={isLinkDialogOpen} onClose={() => setIsLinkDialogOpen(false)}>
        <DialogTitle>Add a Link</DialogTitle>
        <DialogContent>
          <TextField
            label="Link URL"
            value={linkUrl}
            onChange={(e) => setLinkUrl(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsLinkDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleLinkInsert}>Insert</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
});

export default connect(mapStateToProps)(BlogPostEditor);